import React, { useState, useRef, useEffect } from "react"
import Image from "../../atoms/Image/Image"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import { Link } from "gatsby"
import ReactModal from "react-modal"
import { css } from "@emotion/react"

export default function CardTodaLaVida ({data, key, linkWsp}){

  const [isModalOpen, setIsModalOpen] = useState(false);
  const cardRef = useRef(null);

  const handleModalOpen = async () => {
    setIsModalOpen(true)
  }

  const handleModalClose = event => {
    setIsModalOpen(false)
  }

  const isBrowser = () => typeof window !== "undefined";

  const cardProjectCss = css`
    width: calc(100vw * (calc(264 / var(--width_base))));
    height: calc(100vw * (calc(465 / var(--width_base))));
    background-color: transparent;
    border-radius: calc(100vw * (calc(20 / var(--width_base))));
    text-align: left;
    margin-right: 0;
    margin-left: 0;
    overflow: hidden;
    @media (max-width: 1440px) and (min-width: 1025px) {
      width: calc(100vw * (calc(205 / var(--width_base))));
      height: calc(100vw * (calc(375 / var(--width_base))));
    }
    @media (max-width: 1024px) and (min-width: 768px) {
      width: calc(100vw * (calc(142 / var(--width_base))));
      height: calc(100vw * (calc(240 / var(--width_base))));
    }
    @media (max-width: 767px) {
      width: calc(100vw * (calc(335 / var(--width_base))));
      height: calc(100vw * (calc(412 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
      margin-right: 0;
      margin-left: 0;
    }
    
    &:not(:last-of-type){
      margin-right: calc(100vw * (calc(20 / var(--width_base))));
      @media (max-width: 767px) {
        margin-right: initial;
      }
    }
  `;

  const textWhatsapp2Css = css`
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    color: #fff;
    background-color: rgb(255, 114, 128);
    font-family: "Poppins Regular", sans-serif;
    height: calc(100vw * (calc(35 / var(--width_base))));
    width: calc(100vw * (calc(253 / var(--width_base))));
    display: none;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: calc(100vw * (calc(10 / var(--width_base))));
    & img {
      width: calc(100vw * (calc(20 / var(--width_base))));
      margin-right: calc(100vw * (calc(8 / var(--width_base))));
    }
    & span {
      font-family: "Poppins SemiBold", sans-serif;
    }

    @media (max-width: 767px) {
      display: flex;
    }
  `

  const textFooterCss = css`
    font-size: calc(100vw * (calc(21 / var(--width_base))));
    line-height: calc(100vw * (calc(15 / var(--width_base))));
    color: #000;
    font-family: "Poppins Light", sans-serif;
    & span {
      color: #29a3da;
    }
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(12 / var(--width_base))));
      line-height: calc(100vw * (calc(8 / var(--width_base))));
    }
  `

  const sectionFooterCss = css`
    background-color: #ffffff;
    text-align: center;
    padding-top: calc(100vw * (calc(37 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(37 / var(--width_base))));
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(30 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `

  const cardProjectimg1Css = css`
    display: block !important;
    height: calc(100vw * (calc(270 / var(--width_base))));
    width: 100%;
    object-fit: cover;
    border-top-left-radius: calc(100vw * (calc(20 / var(--width_base))));
    border-top-right-radius: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 1440px) and (min-width: 1025px) {
      height: calc(100vw * (calc(200 / var(--width_base))));

    }
    @media (max-width: 1024px) and (min-width: 768px) {
      height: calc(100vw * (calc(190 / var(--width_base))));

    }
    @media (max-width: 767px) {
      display: none !important;
    }
  `
  const cardProjectimg2Css = css`
    display: none !important;
    @media (max-width: 767px) {
      display: block !important;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: calc(100vw * (calc(20 / var(--width_base))));
      border-top-right-radius: calc(100vw * (calc(20 / var(--width_base))));
    }
  `
  const cardProject2Css = css`
    text-align: left;
    padding-top: calc(100vw * (calc(24 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(13 / var(--width_base))));
    padding-right: calc(100vw * (calc(15 / var(--width_base))));
    padding-left: calc(100vw * (calc(15 / var(--width_base))));
    @media (max-width: 767px) {
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vw * (calc(53 / var(--width_base))));
    }
  `
  const nameProjectCss = css`
    font-family: "Poppins SemiBold", sans-serif;
    color: rgb(48, 48, 47);
    font-weight: normal;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(24 / var(--width_base))));
    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
    }
    @media (max-width: 1024px) and (min-width: 768px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
    }
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(13 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
      display: none;
    }
  `
  const districtProjectCss = css`
    font-family: "Poppins Regular", sans-serif;
    color: rgb(48, 48, 47);
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(13 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(14 / var(--width_base))));
      display: none;
    }
  `
  const listProjectCss = css`
    font-family: "Poppins Light", sans-serif;
    color: rgb(48, 48, 47);
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(21 / var(--width_base))));
    & ul {
      list-style: inside;
    }
    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(12 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
    }
    @media (max-width: 1024px) and (min-width: 768px) {
      font-size: calc(100vw * (calc(12 / var(--width_base))));
    }
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(12 / var(--width_base))));
      line-height: calc(100vw * (calc(15 / var(--width_base))));
      display: none;
      & ul {
        list-style: inside;
      }
    }
  `;

  const reverseTextProjectCss = css`
    font-family: "Poppins Light", sans-serif;
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    line-height: calc(100vw * (calc(26 / var(--width_base))));
    letter-spacing: calc(100vw * (calc(3 / var(--width_base))));
    width: 100%;
    background-color: rgb(233, 233, 235);
    height: calc(100vw * (calc(133 / var(--width_base))));
    display: flex;
    justify-content: center;
    align-items: center;
    & strong {
      font-family: "Poppins SemiBold", sans-serif;
      font-weight: normal;
    }
    @media (max-width: 1440px) and (min-width: 1025px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
    }
    @media (max-width: 1024px) and (min-width: 768px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
    }
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(15 / var(--width_base))));
      line-height: calc(100vw * (calc(18 / var(--width_base))));
      width: 100%;
    }
  `

  const contentModalCss = css``;

  const reverseTextTitleProjectCss = css`
    width: auto;
    margin-bottom: auto;
    font-family: "Poppins SemiBold",sans-serif;
    padding: calc(100vw * (calc(15 / var(--width_base))));
    font-size: calc(100vw * (calc(33 / var(--width_base))));
    line-height: calc(100vw * (calc(40 / var(--width_base))));
    color: rgb(48, 48, 47);
  `;
  const modalProjectImgCss = css`
    width: 100%;
  `;

  const modalProjectCss = css`
    padding-top: calc(100vw * (calc(32 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(23 / var(--width_base))));
    padding-inline: calc(100vw * (calc(28 / var(--width_base))));
    border-top-left-radius: calc(100vw * (calc(20 / var(--width_base))));
    border-top-right-radius: calc(100vw * (calc(20 / var(--width_base))));
    position: relative;
    background-color: rgb(245, 245, 245);
  `
  const nameModalProjectCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    color: rgb(48, 48, 47);
    font-family: "Poppins SemiBold",sans-serif;
  `
  const districtModalProjectCss = css`
    font-size: calc(100vw * (calc(13 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    color: rgb(48, 48, 47);
    margin-bottom: calc(100vw * (calc(16 / var(--width_base))));
    font-family: "Poppins Regular",sans-serif;
  `
  const listModalProjectCss = css`
    font-size: calc(100vw * (calc(13 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    color: rgb(48, 48, 47);
    font-family: "Poppins Regular",sans-serif;
    & ul {
      list-style: inside;
    }
  `
  const reverseModalTextTitleProjectCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    color: rgb(48, 48, 47);
    font-family: "Poppins SemiBold",sans-serif;
    margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(23 / var(--width_base))));
      line-height: calc(100vw * (calc(28 / var(--width_base))));
      font-family: "Poppins SemiBold",sans-serif;
      text-align: center;
    }
  `
  const reverseModalTextProjectCss = css`
    font-family: "Poppins Regular", sans-serif;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    letter-spacing: calc(100vw * (calc(3 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    background-color: rgb(233, 233, 235);
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vw * (calc(72 / var(--width_base))));
    width: 100%;
    & strong {
      font-family: "Poppins SemiBold", sans-serif;
      font-weight: normal;
    }
  `
  const hr1 = css`
    border: 2px solid rgb(48, 48, 47);
    margin-block: calc(100vw * (calc(28 / var(--width_base))));
  `
  const closeButton = css`
    position: absolute;
    z-index: 10;
    top: calc(100vw * (calc(20 / var(--width_base))));
    right: calc(100vw * (calc(20 / var(--width_base))));
    width: calc(100vw * (calc(35 / var(--width_base))));
    border-radius: 100%;
    background-color: rgba(245, 245, 245, 0.95);
    height: calc(100vw * (calc(35 / var(--width_base))));
    display: flex;
    padding: calc(100vw * (calc(10 / var(--width_base))));
  `;

  useEffect(()=>{
    if (cardRef.current){
      cardRef.current.addEventListener('mouseenter', ()=>{
          if ( isBrowser() && window.screen.width >= 768) {
            var cardos = cardRef.current.getElementsByClassName("flip-card-back");
            cardos[0].classList.add("flip-card-back-visible");
            cardos[0].childNodes[0].classList.add(
              "flip-card-back-visibility"
            );
          }
      });

      cardRef.current.addEventListener('mouseleave', ()=>{
          if (isBrowser() && window.screen.width >= 768) {
            var cardos = cardRef.current.getElementsByClassName("flip-card-back")
            cardos[0].classList.remove("flip-card-back-visible")
            cardos[0].childNodes[0].classList.remove(
              "flip-card-back-visibility"
            )
          }
      });

      return cardRef.current.removeEventListener("mouseenter,mouseleave",()=>{});
    }

  },[cardRef]);


  return(
    <>
      <div css={cardProjectCss} key={key} ref={cardRef}>
        <div className="flip-card">
          <div
            aria-hidden="true"
            className="flip-card-inner"
            onClick={handleModalOpen}
          >
            <div className="flip-card-front">
              <Image
                imagen={data.imagen}
                alt={data.proyecto}
                styleCss={cardProjectimg1Css}
              />
              <Image
                imagen={data.imagenMovil}
                alt={data.proyecto}
                styleCss={cardProjectimg2Css}
              />
              <div css={cardProject2Css}>
                <Paragraph
                  text={data.proyecto}
                  styleCss={nameProjectCss}
                />
                <Paragraph
                  text={data.distrito}
                  styleCss={districtProjectCss}
                />
                <Paragraph text={data.datos} styleCss={listProjectCss} />
                <Paragraph
                  text={"+ más información"}
                  classEle={"plus-text"}
                />
              </div>
            </div>
            {isBrowser() && window.screen.width >= 768 && (
              <div className="flip-card-back">
                {data.linkDeProyecto ? (
                  <Link to={`${data.linkDeProyecto}`} target="_blank">
                    <Paragraph
                      text={"PROMO"}
                      styleCss={reverseTextTitleProjectCss}
                    />
                    <Paragraph
                      text={data.textoReverso1}
                      styleCss={reverseTextProjectCss}
                    />
                    <hr />
                    <Paragraph
                      text={data.textoReverso2}
                      styleCss={reverseTextProjectCss}
                    />
                  </Link>
                ) : (
                  <Link>
                    <Paragraph
                      text={"PROMO"}
                      styleCss={reverseTextTitleProjectCss}
                    />
                    <Paragraph
                      text={data.textoReverso1}
                      styleCss={reverseTextProjectCss}
                    />
                    <hr />
                    <Paragraph
                      text={data.textoReverso2}
                      styleCss={reverseTextProjectCss}
                    />
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isBrowser() && window.screen.width <= 768 && (
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={handleModalClose}
          contentLabel="Modal Cotizar"
        >
          <div css={contentModalCss}>
            <div>
              <div className="text-right">
                <button onCLick={handleModalClose} css={closeButton}>
                  <img
                    src="https://apros-qa.net.pe/armandoparedes/wp-content/uploads/2022/03/Close-x.png"
                    alt="x"
                  />
                </button>
              </div>
              <div className="">
                <Image
                  imagen={data.imagen}
                  alt={data.proyecto}
                  styleCss={modalProjectImgCss}
                />
                <div css={modalProjectCss}>
                  <Paragraph
                    text={data.proyecto}
                    styleCss={nameModalProjectCss}
                  />
                  <Paragraph
                    text={data.distrito}
                    styleCss={districtModalProjectCss}
                  />
                  <Paragraph
                    text={data.datos}
                    styleCss={listModalProjectCss}
                  />
                  <hr css={hr1} />
                  <Paragraph
                    text={"PROMO"}
                    styleCss={reverseModalTextTitleProjectCss}
                  />
                  <Paragraph
                    text={data.textoReverso1}
                    styleCss={reverseModalTextProjectCss}
                  />
                  <Paragraph
                    text={data.textoReverso2}
                    styleCss={reverseModalTextProjectCss}
                  />
                  <Link
                    to={`${linkWsp}`}
                    css={textWhatsapp2Css}
                    target="_blank"
                  >
                    <img
                      src="https://apros-qa.net.pe/armandoparedes/wp-content/uploads/2022/03/Recurso-7.png"
                      alt="Contacto asesor"
                    />
                    Contactar asesor
                  </Link>
                </div>
                <div css={sectionFooterCss}>
                  <Link
                    to={`https://www.armandoparedes.com/terminosycondiciones/`}
                    css={textFooterCss}
                  >
                    Ver términos y condiciones <span>aquí</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      )}
    </>
  )
}
